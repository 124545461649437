import qs from 'qs'

export const queryNewsEntryThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      filters: options?.filters || {},
      publicationState: options?.publicationState || 'live',
      sort: ['date_posted:desc', `${sortKey}:asc`],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 9,
      },
      ...thumbnailNewsEntry,
    },
    {
      encodeValuesOnly: true
    }
  )

  return `news-entries?${query}`
}
